import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cashier: "",
    cashierURL: "https://keno.retailtwo.playapicevirtual-h006.com/graphql",
    spinCashierURL: "https://spin.retailtwo.playapicevirtual-h006.com/graphql",

    // cashierURL: "http://localhost:8087/graphql",
    // spinCashierURL: "http://localhost:8088/graphql",

    odds: [],
    spinOdds: [],
    kenoTicketNumber: 0,
    kenoTicketNumberDate: "",
    spinTicketNumber: 0,
    spinTicketNumberDate: "",
    sessionTime: "",
  },
  getters: {},
  mutations: {
    loginCashier(state, cashier) {
      state.cashier = cashier;
    },
    sessionTime(state, sessionTime) {
      state.sessionTime = sessionTime;
    },

    odds(state, odds) {
      state.odds = odds;
    },
    spinOdds(state, spinOdds) {
      state.spinOdds = spinOdds;
    },

    kenoTicketNumberDate(state, kenoTicketNumberDate) {
      state.kenoTicketNumberDate = kenoTicketNumberDate;
    },

    kenoTicketNumber(state, kenoTicketNumber) {
      state.kenoTicketNumber = kenoTicketNumber;
    },

    spinTicketNumber(state, spinTicketNumber) {
      state.spinTicketNumber = spinTicketNumber;
    },

    spinTicketNumberDate(state, spinTicketNumberDate) {
      state.spinTicketNumberDate = spinTicketNumberDate;
    },
  },
  actions: {
    loginCashier(context, cashier) {
      context.commit("loginCashier", cashier);
    },

    sessionTime(context, sessionTime) {
      context.commit("sessionTime", sessionTime);
    },

    odds(context, odds) {
      context.commit("odds", odds);
    },
    spinOdds(context, spinOdds) {
      context.commit("spinOdds", spinOdds);
    },

    kenoTicketNumberDate(context, kenoTicketNumberDate) {
      context.commit("kenoTicketNumberDate", kenoTicketNumberDate);
    },

    kenoTicketNumber(context, kenoTicketNumber) {
      context.commit("kenoTicketNumber", kenoTicketNumber);
    },

    spinTicketNumber(context, spinTicketNumber) {
      context.commit("spinTicketNumber", spinTicketNumber);
    },
    spinTicketNumberDate(context, spinTicketNumberDate) {
      context.commit("spinTicketNumberDate", spinTicketNumberDate);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
